
form[name=agency-switch-form] {
  display: flex;
}
form[name=agency-switch-form] > div {
  display: flex;
  margin-bottom: 0;
  flex-grow:1;
}
form[name=agency-switch-form] > div > label {
  margin-right:10px;
  line-height: 36px;
  margin-bottom: 0px;
}
form[name=agency-switch-form] > div > div {
  flex-grow:1;
}
form[name=agency-switch-form] > button {
  margin-left:10px;
}


:root {
  --ck-z-default: 2200
}
.localizedContent .nav.nav-tabs li {
  margin-bottom: -1px;
}
.localizedContent.has-success .nav-tabs, 
.localizedContent.has-success .ck.ck-toolbar {
  border-color: #1ab394;
}
.localizedContent.has-error .nav-tabs,
.localizedContent.has-error .ck.ck-toolbar {
  border-color: #ed5565;
}
.localizedContent.has-success .nav-tabs .nav-link.active {
  border-color: #1ab394 #1ab394 #fff;
}
.localizedContent.has-error .nav-tabs .nav-link.active {
  border-color: #ed5565 #ed5565 #fff;
}
.localizedContent .ck.ck-toolbar {
  border-top:0px;
}
.localizedContent.has-success .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused),
.localizedContent.has-success .ck.ck-editor__main>.ck-source-editing-area>textarea {
  border-color: var(--ck-color-base-border) #1ab394 #1ab394 ;
}
.localizedContent.has-error .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused),
.localizedContent.has-error .ck.ck-editor__main>.ck-source-editing-area>textarea {
  border-color: var(--ck-color-base-border) #ed5565 #ed5565 ;
}
.localizedContent .ck.ck-editor__main>.ck-editor__editable,
.localizedContent .ck.ck-editor__main>.ck-source-editing-area {
  min-height:200px;
}
.localizedContent .wordCount { 
  text-align: right;
  padding: 5px;
  border: 1px solid var(--ck-color-toolbar-border);
  border-top: 0px;
  background-color: var(--ck-color-toolbar-background);
}



.tox-tinymce-aux {
  z-index:2200 !important;
}




.localizedText .nav.nav-tabs li {
  margin-bottom: -1px;
}
.localizedText textarea {
  border-top:0px;
}
.localizedText.has-success .nav-tabs {
  border-color: #1ab394;
}
.localizedText.has-error .nav-tabs {
  border-color: #ed5565;
}
.localizedText.has-success .nav-tabs .nav-link.active {
  border-color: #1ab394 #1ab394 #fff;
}
.localizedText.has-error .nav-tabs .nav-link.active {
  border-color: #ed5565 #ed5565 #fff;
}


.listPictures table.pictures th {
    width:50%;
}
.listPictures table.pictures th.ranking {
    width:15px;
}
.listPictures table.pictures td.thumbnail {
    text-align: center;
}
.listPictures table.pictures td.thumbnail img {
    max-width: 400px;
    max-height: 200px;
}
.listPictures table.pictures td.options button {
    display:block;
}
.listPictures table.pictures td.ranking a {
    display: block;
}
.listPictures .picture-upload,
  .listPictures .picture-add {
    margin-top: 5px;
}



.apartment-form .update-description textarea {
  min-height: 100px;
}


.fa-check.active[data-v-4f5095f5], .fa-user-circle-o.active[data-v-4f5095f5] {
  color:green;
}
.fa-check.disabled[data-v-4f5095f5], .fa-user-circle-o.disabled[data-v-4f5095f5] {
  color:red;
}




.fa-check.active[data-v-9876cda4], .fa-user-circle-o.active[data-v-9876cda4] {
  color:green;
}
.fa-check.disabled[data-v-9876cda4], .fa-user-circle-o.disabled[data-v-9876cda4] {
  color:red;
}



